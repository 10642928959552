var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Determines if a passed in value is a primitive or complex type
 * @param val any potential primitive or not primitive
 */

var isPrimitive = function (val) {
  return Object(val) !== val;
};

var Fra =
/** @class */
function () {
  /**
   * You can instantiate a new mapper or use the static createMap method
   * @param target output target class for the mapper. This is used to instantiate new objects on map.
   */
  function Fra(target) {
    this.target = null;
    this.fields = [];
    this.target = target;
  }
  /**
   * Static helper method for instatiating a Fra object. Makes building off a chain nicer.
   * @param target same as constructor
   */


  Fra.createMap = function (target) {
    return new Fra(target);
  };
  /**
   * Allows you to explicitly map fields. Good for changing names, mapping from nested objects or other mappers, etc
   * @param fieldName name of the field on the target object
   * @param callback method that passes in the sourceValue object and expects back a match to the field type
   */


  Fra.prototype.field = function (fieldName, callback) {
    this.fields.push({
      callback: callback,
      fieldName: fieldName
    });
    return this;
  };
  /**
   * Map the source object to an existing or new target object
   * @param sourceValue object to map from
   * @param initialTarget object to map to. if left empty a new one will be created from target.
   */


  Fra.prototype.map = function (sourceValue, initialTarget) {
    if (sourceValue === null || sourceValue === undefined) {
      return null;
    }

    return this.innerMap(sourceValue, initialTarget);
  };
  /**
   * Helper wrapper for mapping array of source objects to an array of new targets
   * @param sourceValues array of objects to map from
   */


  Fra.prototype.mapAll = function (sourceValues) {
    var _this = this;

    return Array.isArray(sourceValues) ? sourceValues.map(function (sourceValue) {
      return _this.map(sourceValue);
    }) : null;
  };
  /**
   * Performs the mapping logic
   * @param sourceValue object to map from
   * @param instance object to map to or create a new one
   */


  Fra.prototype.innerMap = function (sourceValue, instance) {
    if (instance === void 0) {
      instance = new this.target();
    }

    for (var key in sourceValue) {
      if (sourceValue.hasOwnProperty(key) && instance.hasOwnProperty(key) && isPrimitive(sourceValue[key])) {
        instance[key] = sourceValue[key];
      }
    }

    for (var _i = 0, _a = this.fields; _i < _a.length; _i++) {
      var field = _a[_i];
      instance[field.fieldName] = field.callback(sourceValue);
    }

    return instance;
  };

  return Fra;
}();
/**
 * Compose multiple mappers together to return one object
 * @param target class to instantiate and infers the acceptable types of Fra mappers
 * @param sources mappers that match any source type to the target type
 */


var link = function (target, sources) {
  /**
   * source objects to map to the target
   */
  return function () {
    var values = [];

    for (var _i = 0; _i < arguments.length; _i++) {
      values[_i] = arguments[_i];
    }

    var instance = new target();
    return sources.reduce(function (prev, curr, index) {
      return curr.map(values[index], prev);
    }, instance);
  };
};

exports.isPrimitive = isPrimitive;
exports.Fra = Fra;
exports.link = link;
export default exports;
export const __esModule = exports.__esModule;
const _isPrimitive = exports.isPrimitive,
      _Fra = exports.Fra,
      _link = exports.link;
export { _isPrimitive as isPrimitive, _Fra as Fra, _link as link };